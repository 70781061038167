import React, { useCallback, useEffect, useState } from 'react';
import { Block, Chip, ListItem } from 'konsta/react';
import { IVideo } from '../types/app';
import { formatDate } from '../helpers/utils';

interface Props {
    item: IVideo;
    active?: boolean;
    onSelect: (item: IVideo) => void;
}

function Video(props: Props) {
    const { item, active, onSelect } = props;

    // {active ? 'attivo' : 'non attivo'}
    return (
        <ListItem
            onClick={() => onSelect(item)}
            link
            chevronMaterial={false}
            title={formatDate(item.date)}
            subtitle={item.title}
            text={<>
                    {item.location && <div>{item.location.address}</div>}
                    {item.persons && <>
                        <div>
                            {item.persons.map((p, i) => {
                                const [firstName, ] = p.split(' ');
                                return firstName;
                            }).join(', ')}
                        </div>
                    </>}
            </>}
            media={
                item.media ? <img
                    className="ios:rounded-lg material:rounded-full ios:w-30 material:w-30"
                    src={item.media.thumbnail}
                    width="160"
                    alt={item.title}
                /> : undefined
            }
        />
    );
}

export default Video;
