import React, { useCallback, useEffect, useState } from 'react';

function Empty() {
    return (
        <div>
            EMPTY
        </div>
    );
}

export default Empty;
