import React, { useCallback, useEffect, useRef, useState } from 'react';
import { interval, skipUntil, take, toArray } from 'rxjs';
import styled from 'styled-components'
import { IMessage } from '../../types/app';
import useMessages from '../../hooks/useMessages';

const Styled = styled.div`

`;

interface Props {
}

function Messages(props: Props) {
    const { } = props;
    const { getMessagesStream } = useMessages();
    const [messages, setMessages] = useState<IMessage[]>();

    /*
    useEffect(() => {
        const intervalEvents = interval(10000);
        const emitted = getMessagesStream().pipe(
            // skipUntil(intervalEvents),
            take(5),
            // toArray(),
        );
        emitted.subscribe(items => {
            console.log('emitted', items);
           // setMessages(items);
        });

        getMessagesStream().subscribe(v => {
            console.log('src', v);
        });
    }, []);
    */

    if (!messages) return null;
    return <Styled>
        MESSAGES:
        {messages.map((msg, i) => <div key={i}>
            {JSON.stringify(msg)}
        </div>)}
    </Styled>;
}

export default Messages;
