import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, DialogButton, Link } from 'konsta/react';
import { IFilters } from '../types/app';
import { setItems } from '../state/slices/video';
import { $storage } from '../state/slices/auth';
import { useAppDispatch } from '../state/hooks';
import useData from '../hooks/useData';
import { getStorage } from '../helpers/platform';
import { POLLING_UPDATE_SEC } from '../constants';
import { createPortal } from 'react-dom';

function Updater() {
    const [hasUpdates, setHasUpdates] = useState(false);
    const [wantToSync, setWantToSync] = useState(false);
    const dispatch = useAppDispatch();
    const { load, refresh, getMeta } = useData();

    useEffect(() => {
        checkVersion();
    }, []);

    const checkVersion = useCallback(async () => {
        const currentMetaRaw = getStorage().getItem($storage.metaKey);
        const currentMeta = currentMetaRaw ? JSON.parse(currentMetaRaw) : {};
        const meta = await getMeta();
        if (meta && currentMeta && currentMeta.version !== meta.version) {
            setHasUpdates(true);
        }
        setTimeout(() => {
            checkVersion();
        }, POLLING_UPDATE_SEC * 1000)
    }, []);

    const refreshItems = useCallback(async () => {
        await refresh();
        const items = await load();
        dispatch(setItems(items));
        setWantToSync(false);
        setHasUpdates(false);
        const meta = await getMeta();
        getStorage().setItem($storage.metaKey, JSON.stringify(meta));
    }, [refresh, load]);

    if (!hasUpdates) return null;
    return <>
        <Link navbar onClick={() => setWantToSync(true)}>Aggiorna</Link>
        
        {createPortal(<Dialog
            opened={wantToSync}
            onBackdropClick={() => setWantToSync(false)}
            title="Aggiornamento"
            content="Vuoi aggiornare il database all'ultima versione?"
            buttons={
                <>
                    <DialogButton onClick={() => refreshItems()}>
                    Procedi
                    </DialogButton>
                </>
            }
        />, document.body)}
    </>;
}

export default Updater;
