import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../types/app';
import type { RootState } from '../store';
import { getStorage } from '../../helpers/platform';

interface AuthState {
    user?: IUser;
    token?: string;
}

const initialState: AuthState = {}

export const $storage = {
    tokenKey: 'auth:token',
    metaKey: 'meta:data',
};
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            getStorage().setItem($storage.tokenKey, action.payload);
            state.token = action.payload;
        },
        forgetToken: (state) => {
            getStorage().removeItem($storage.tokenKey);
            state.token = undefined;
        },
    },
})

export const { setToken, forgetToken } = authSlice.actions

export const selectUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer