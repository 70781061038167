import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, Block, BlockTitle, Checkbox, Link, List, ListItem, Navbar, NavbarBackLink, Page, Popup, Searchbar, Tabbar, TabbarLink } from 'konsta/react';
import { IFilters } from '../../types/app';
import useData from '../../hooks/useData';
import { initialState, selectCurrentFilters, selectFilters, setCurrentFilters, setFilters } from '../../state/slices/video';
import { useAppDispatch, useAppNavigate, useAppSelector } from '../../state/hooks';

const TABS = [
    { key: 'persons', title: 'Presenti' },
    { key: 'period', title: 'Anni' },
    { key: 'geo', title: 'Luoghi' },
]

function Filters() {
    const navigate = useAppNavigate();
    const { loadFilters } = useData();
    const dispatch = useAppDispatch();
    const allFilters = useAppSelector(selectFilters);
    const filters = useAppSelector(selectCurrentFilters);
    const [activeTab, setActiveTab] = useState('persons');

    const loadAllFilters = useCallback(async () => {
        const allFilters = await loadFilters();
        dispatch(setFilters(allFilters));
    }, []);

    const handleSearch = (e: any) => {
        dispatch(setCurrentFilters({ ...filters, query: e.target.value }));
    };
    const handleClear = () => {
        dispatch(setCurrentFilters({ ...filters, query: '' }));
    };
    const handleDisable = () => {
        console.log('Disable');
    };

    useEffect(() => {
        loadAllFilters();
    }, []);

    return <Page>
        <Navbar title="Filtri"
            left={<NavbarBackLink text="Indietro" onClick={() => navigate('/library')} />}
            right={<Link navbar onClick={() => dispatch(setCurrentFilters({ ...initialState.filters }))}>Azzera</Link>}
        />
        <Block>
            <Searchbar
                value={filters.query}
                onInput={handleSearch}
                onClear={handleClear}
                onDisable={handleDisable}
                disableButton
                disableButtonText="Cancel"
            />
        </Block>
        <Tabbar
            labels={true}
            className="left-0 bottom-0 fixed"
        >
            {TABS.map(({key, title}) => (
                <TabbarLink
                    key={key}
                    active={activeTab === key}
                    onClick={() => setActiveTab(key)}
                    label={title}
                />
            ))}
        </Tabbar>
        {allFilters && <>
            {activeTab === 'persons' && <Block>
                <BlockTitle>Persone presenti nel video</BlockTitle>
                <List>
                    {allFilters.persons.map((row) => {
                        const key = row.name;
                        return <ListItem
                            key={key}
                            label
                            title={key}
                            after={<Badge>{row.count}</Badge>}
                            media={
                                <Checkbox
                                    component="div"
                                    name={key}
                                    checked={filters.persons.includes(key)}
                                    onChange={() => {
                                        const newPersons = filters.persons.includes(key) ? filters.persons.filter(p => p !== key) : [...filters.persons, key];
                                        dispatch(setCurrentFilters({ ...filters, persons: newPersons }));
                                    }}
                                />
                            }
                        />
                    })}
                </List>
            </Block>}
            {activeTab === 'period' && <Block>
                <BlockTitle>Anni</BlockTitle>
                <List>
                    {allFilters.years.map((row) => {
                        const key = row.name;
                        return <ListItem
                            key={key}
                            label
                            title={key}
                            after={<Badge>{row.count}</Badge>}
                            media={
                                <Checkbox
                                    component="div"
                                    name={key}
                                    checked={filters.years.includes(key)}
                                    onChange={() => {
                                        const newYears = filters.years.includes(key) ? filters.years.filter(p => p !== key) : [...filters.years, key];
                                        dispatch(setCurrentFilters({ ...filters, years: newYears }));
                                    }}
                                />
                            }
                        />
                    })}
                </List>
            </Block>}
            {activeTab === 'geo' && <Block>
                <BlockTitle>Luoghi</BlockTitle>
                <List>
                    {allFilters.locations.map((row) => {
                        const key = row.name;
                        return <ListItem
                            key={key}
                            label
                            title={key}
                            after={<Badge>{row.count}</Badge>}
                            media={
                                <Checkbox
                                    component="div"
                                    name={key}
                                    checked={filters.locations.includes(key)}
                                    onChange={() => {
                                        const newLocations = filters.locations.includes(key) ? filters.locations.filter(p => p !== key) : [...filters.locations, key];
                                        dispatch(setCurrentFilters({ ...filters, locations: newLocations }));
                                    }}
                                />
                            }
                        />
                    })}
                </List>
            </Block>}
        </>}
    </Page>;
}

export default Filters;
