import Dexie from 'dexie';
import { DB_NAME } from '../constants';

export function getStorage() {
    return window.localStorage;
}

export function getHistory() {
    return window.history;
}

export function getHash() {
    const hash = window.location.hash;
    if (hash.length > 1) {
        return hash.substring(1);
    }
}

export function isCordova() {
    return !!(window as any).cordova;
};

export function openYouTubeVideo(url: string) {
    window.open(url, '_blank');
}

export async function isDatabaseReadyForOffline(collectionName: string) {
    return await Dexie.exists(`rxdb-dexie-${DB_NAME}--0--${collectionName}`)
}