import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getHash, getStorage } from '../helpers/platform';
import useData from '../hooks/useData';
import { useAppDispatch, useAppNavigate } from '../state/hooks';
import { $storage, forgetToken, setToken } from '../state/slices/auth';
import { setCurrentFilters } from '../state/slices/video';
import { decode } from '../helpers/encoding';
import Messages from './partials/Messages';

function Wrapper() {
    const [isBusy, setIsBusy] = useState(true); 
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { check } = useData();

    useEffect(() => {
        const hash = getHash();
        if (hash) {
            const filters = decode(hash);
            dispatch(setCurrentFilters({ ...filters }));
        }
    }, []);

    useEffect(() => {
        (async () => {
            const token = getStorage().getItem($storage.tokenKey);
            if (token) {
                try {
                    setIsBusy(true);
                    await check(token);
                    dispatch(setToken(token));
                } catch(e) {
                    // forgot token and go to login
                    dispatch(forgetToken());
                    navigate('/login');
                } finally {
                    setIsBusy(false);
                }
            } else {
                setIsBusy(false);
            }
        })();
    }, []);

    if (isBusy) return null;
    return (
        <div>
            <Outlet />

            <Messages />
        </div>
    );
}

export default Wrapper;
