import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as Rx from 'rxjs';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
// @ts-ignore
import { RxDatabase, createRxDatabase, addRxPlugin } from 'rxdb/plugins/core';
// @ts-ignore
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { DB_NAME } from '../constants';
import { IMessage } from '../types/app';
import { DB_METHODS_COLLECTION_VIDEO, DB_METHODS_DOC_VIDEO, DB_SCHEMA_VIDEO, VideoCollection } from "../types/schema/video";

export type DatabaseCollections = {
    video: VideoCollection;
};
export type Database = RxDatabase<DatabaseCollections>;

interface ContextValue {
    dev?: boolean;
    getClient: () => AxiosInstance;
    getMessagesStream: () => Rx.Subject<IMessage>;
    getDataBase: () => any;
    onBusy: (state?: boolean) => void;
}

export const DataContext = React.createContext<ContextValue>({
    // @ts-ignore
    getClient: () => {},
    // @ts-ignore
    getMessagesStream: () => {},
    // @ts-ignore
    getDataBase: () => {},
    onBusy: () => {},
});

function DataProvider(props: any) {
    const [busy, setBusy] = useState(false);

    const createHttpClient = useCallback((messageStream: Rx.Subject<IMessage>) => {   
        const current = `${window.location.protocol}//${window.location.host}`;
        const baseURL = process.env.REACT_APP_BASE_URL || current;
        
        const client = axios.create({
            baseURL: baseURL,
        });
        client.interceptors.request.use(
            config => {
                onBusy(true);
                return config;
            }, error => {
                onBusy(false);
                return Promise.reject(error);
            }
        );
        client.interceptors.response.use(
            response => {
                const res = response.data;
                const { result, error, data } = res as any;
                onBusy(false);
                if (!result) {
                    messageStream.next({ type: 'error', payload: error });
                }
                return response;
            },
            error => {
                onBusy(false);
                messageStream.next({ type: 'error', text: `Internal error: ${error.message}`, payload: error });
                return Promise.reject(error);
            }
        );
        return client;
    }, []);

    const createMessageStream = useCallback(() => {
        const messageStream = new Rx.Subject<IMessage>();
        return messageStream;
    }, []);

    const { client, messagesStream } = useMemo(() => {
        const messagesStream = createMessageStream();
        const client = createHttpClient(messagesStream);
        return { client, messagesStream };
    }, []);

    const db = useMemo(async () => {
        addRxPlugin(RxDBDevModePlugin);

        // create database
        const db: Database = await createRxDatabase<DatabaseCollections>({
            name: DB_NAME,
            storage: getRxStorageDexie(),
            multiInstance: false,
            ignoreDuplicate: true,
        });
        // attach collections
        await db.addCollections({
            video: {
                schema: DB_SCHEMA_VIDEO,
                methods: DB_METHODS_DOC_VIDEO,
                statics: DB_METHODS_COLLECTION_VIDEO
            }
        });
        return db;
    }, []);

    const getClient = useCallback(() => {
        return client;
    }, [client]);

    const getMessagesStream = useCallback(() => {
        return messagesStream;
    }, [messagesStream]);

    const getDataBase = useCallback(() => {
        return db;
    }, [db]);

    const onBusy = useCallback((state?: boolean) => {
        setBusy(!!state);
    }, []);
    
    if (!client || !messagesStream) return null;
    return <DataContext.Provider value={{
        dev: true,
        getClient,
        getMessagesStream,
        getDataBase,
        onBusy,
    }}>
        {props.children}
    </DataContext.Provider>;
}

export default DataProvider;