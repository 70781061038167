import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAllFilters, IFilters, IVideo } from '../../types/app';
import type { RootState } from '../store';
import { getHistory, getStorage } from '../../helpers/platform';
import { formatDate, includesOneOf } from '../../helpers/utils';
import { encode } from '../../helpers/encoding';

interface VideoState {
    allFilters?: IAllFilters;
    filters: IFilters;
    items?: IVideo[];
    viewing?: IVideo;
}

export const initialState: VideoState = {
    filters: {
        query: '',
        persons: [],
        years: [],
        locations: [],
    },
}

export const $storage = {
    filtersKey: 'video:filters',
};
export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        setFilters: (state, action: PayloadAction<any>) => {
            state.allFilters = action.payload;
        },
        setCurrentFilters: (state, action: PayloadAction<IFilters>) => {
            state.filters = action.payload;
            getStorage().setItem($storage.filtersKey, JSON.stringify(action.payload));
            getHistory().pushState(action.payload, '', `#${encode(action.payload)}`);

        },
        setItems: (state, action: PayloadAction<IVideo[]>) => {
            state.items = action.payload;
        },
        setCurrentItem: (state, action: PayloadAction<IVideo | undefined>) => {
            state.viewing = action.payload;
        },
    },
})

export const { setFilters, setCurrentFilters, setItems, setCurrentItem } = videoSlice.actions

export const selectFilters = (state: RootState) => state.video.allFilters;
export const selectCurrentFilters = (state: RootState) => state.video.filters;
export const selectItems = (state: RootState) => state.video.items;
export const selectFilteredItems = (state: RootState) => {
    const allItems = state.video.items;
    const filters = state.video.filters;
    if (!allItems) return allItems;
    return allItems.filter(item => {
        if (filters.query && filters.query.length > 0 && item.title.toLowerCase().indexOf(filters.query) <= 0) {
            return false;
        }
        if (filters.persons && filters.persons.length > 0 && !includesOneOf(filters.persons, item.persons)) {
            return false;
        }
        if (filters.years && filters.years.length > 0 && !includesOneOf(filters.years, [formatDate(item.date, 'yyyy')])) {
            return false;
        }
        if (filters.locations && filters.locations.length > 0 && !includesOneOf(filters.locations, [item.location.address])) {
            return false;
        }
        return true;
    });
};
export const selectCurrentItem = (state: RootState) => state.video.viewing;

export default videoSlice.reducer