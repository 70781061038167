import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavigateOptions, To, useNavigate } from 'react-router-dom'
import type { RootState, AppDispatch } from './store'
import { getHash } from '../helpers/platform'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export const useAppNavigate = () => {
    const nav = useNavigate();

    const navigate = useCallback((to: To, options?: NavigateOptions) => {
        const hash = getHash();
        return nav(hash ? `${to}#${hash}` : to, options);
    }, [nav]);

    return navigate;
}