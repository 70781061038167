import React from 'react';
import { useAppNavigate } from '../../state/hooks';

function Splash() {
    const navigate = useAppNavigate();

    return (
        <div className="flex h-screen" onClick={() => navigate('library')}>
            <div className="m-auto">
                <h3>Personal Video Archive</h3>
            </div>
        </div>
    );
}

export default Splash;
