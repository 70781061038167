import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import videoReducer from './slices/video';

const store = configureStore({
    reducer: {
        auth: authReducer,
        video: videoReducer,
      },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;