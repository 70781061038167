import { useCallback, useContext, useState } from "react";
import { DateTime }  from "luxon";
import { IFilters, IVideo } from "../types/app";
import { DB_SCHEMA_VIDEO, DB_METHODS_DOC_VIDEO, DB_METHODS_COLLECTION_VIDEO } from "../types/schema/video";
import { DataContext } from '../providers/Data';
import { isDatabaseReadyForOffline } from "../helpers/platform";

export default function useData() {
    const { getClient, getDataBase } = useContext(DataContext);

    const API_URL = process.env.REACT_APP_DATA!;
    
    const login = useCallback(async (args: { phone: string; }) => {
        try {
            const { data } = await getClient().post<{ token: string; }>(`${API_URL}/login`, { ...args });
            const { token } = data;
            return token;
        } catch(e) {
            throw e;
        }
    }, []);

    const check = useCallback(async (token: string) => {
        try {
            const { data } = await getClient().post<{ result: boolean; }>(`${API_URL}/check`, { token });
            const { result } = data;
            if (!result) throw new Error();
            return result;
        } catch(e) {
            throw e;
        }
    }, []);

    const load = useCallback(async () => {
        const ready = await isDatabaseReadyForOffline('video');
        const db = await getDataBase();
        if (!ready) {
            await refresh();
        }
        const docs = await db.video.find().exec();
        return docs.map((doc: any) => ({
            ...doc.toJSON(),
        }))
    }, []);

    const refresh = useCallback(async () => {
        const db = await getDataBase();
        const { data } = await getClient().get<IVideo[]>(`${API_URL}/video`);
        const docs = await db.video.find().exec();
        await db.video.bulkRemove(docs.map((i: any) => i.id));
        await db.video.bulkInsert(data);
        return;
    }, []);

    const loadFilters = useCallback(async () => {
        const ready = await isDatabaseReadyForOffline('video');
        const db = await getDataBase();
        if (!ready) {
            await refresh();
        }
        return await db.video.getAllFilters();
    }, []);

    const getMeta = useCallback(async () => {
        try {
            const { data } = await getClient().get(`${API_URL}/meta`);
            return data;
        } catch(e) {
            return undefined;
        }
    }, [])

    return {
        login,
        check,
        load,
        refresh,
        loadFilters,
        getMeta,
    }
}