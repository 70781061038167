import _ from 'lodash';
// @ts-ignore
import { RxCollection, RxDocument, RxJsonSchema } from "rxdb";
import { IVideo } from "../app";
import { formatDate } from "../../helpers/utils";

export type VideoDocMethods = {
    getDate: () => Date;
}

export type VideoCollectionMethods = {
    getAllFilters: () => Promise<any>;
}

export type VideoDocument = RxDocument<IVideo, VideoDocMethods>;
export type VideoCollection = RxCollection<IVideo, VideoDocMethods, VideoCollectionMethods>;

export const DB_SCHEMA_VIDEO: RxJsonSchema<IVideo> = {
    version: 0,
    primaryKey: 'id',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            maxLength: 100 // <- the primary key must have set maxLength
        },
        title: {
            type: 'string'
        },
        source: {
            type: 'string'
        },
        date: {
            type: 'string'
        },
        duration: {
            type: 'string'
        },
        location: {
            type: "object",
            properties: {
                address: {
                    type: "string"
                },
                coords: {
                    type: "array",
                    maxItems: 2
                }
            }
        },
        persons: {
            type: "array"
        }
    },
    required: ['id', 'title', 'date']
}

export const DB_METHODS_DOC_VIDEO: VideoDocMethods = {
    getDate: function(this: VideoDocument) {
        return new Date();
    }
};

export const DB_METHODS_COLLECTION_VIDEO: VideoCollectionMethods = {
    getAllFilters: async function(this: VideoCollection) {
        const allDocs = await this.find().exec();
        // persons
        const personCounter = (allDocs as IVideo[])
            .reduce<string[]>((acc, doc) => ([...acc, ...(doc.persons || [])]), [])
            .reduce<Record<string, number>>((acc, person) => {
                if (!acc[person]) acc[person] = 0;
                acc[person]++;
                return acc;
            }, {});
        const persons = Object.keys(personCounter).map(person => ({ name: person, count: personCounter[person] }));
        persons.sort((a, b) => {
            return b.count - a.count;
        });
        // years
        const yearCounter = (allDocs as IVideo[])
            .reduce<string[]>((acc, doc) => ([...acc, formatDate(doc.date, 'yyyy')]), [])
            .reduce<Record<string, number>>((acc, year) => {
                if (!acc[year]) acc[year] = 0;
                acc[year]++;
                return acc;
            }, {});
        const years = Object.keys(yearCounter).map(year => ({ name: year, count: yearCounter[year] }));
        years.sort((a, b) => {
            return b.count - a.count;
        });
        // locations
        const locationCounter = (allDocs as IVideo[])
            .reduce<string[]>((acc, doc) => ([...acc, _.get(doc, 'location.address')]), [])
            .reduce<Record<string, number>>((acc, addr) => {
                if (!addr) return acc;
                if (!acc[addr]) acc[addr] = 0;
                acc[addr]++;
                return acc;
            }, {});
        const locations = Object.keys(locationCounter).map(addr => ({ name: addr, count: locationCounter[addr] }));
        locations.sort((a, b) => {
            return b.count - a.count;
        });

        return {
            persons,
            years,
            locations,
        };
    }
};