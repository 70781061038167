import React, { useCallback, useEffect } from 'react';
import { Page, Navbar, List, Block, Link, Chip, Button, Sheet } from 'konsta/react';
import { IFilters, IVideo } from '../../types/app';
import { useAppDispatch, useAppNavigate, useAppSelector } from '../../state/hooks';
import useData from '../../hooks/useData';
import Empty from '../Empty';
import Video from '../Video';
import { selectCurrentFilters, selectCurrentItem, selectFilteredItems, setCurrentItem, setItems } from '../../state/slices/video';
import { openYouTubeVideo } from '../../helpers/platform';
import { formatDate } from '../../helpers/utils';
import Updater from '../Updater';

function Library() {
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectCurrentFilters);
    const items = useAppSelector(selectFilteredItems);
    const current = useAppSelector(selectCurrentItem);
    const { load } = useData();

    const loadItems = useCallback(async () => {
        const items = await load();
        dispatch(setItems(items));
    }, [load]);

    const selectItem = useCallback((item: IVideo) => {
        dispatch(setCurrentItem(item));
    }, []);

    const unSelectItem = useCallback(() => {
        dispatch(setCurrentItem(undefined));
    }, []);
    
    useEffect(() => {
        loadItems();
    }, []);

    return (
        <Page>
            <Navbar title="Videoteca"
                left={<Link navbar onClick={() => navigate('/filters')}>Cerca</Link>}
                right={<Updater />}
            />
            <List margin={''} strongIos outlineIos>
                {items && items.length === 0 && <Empty />}
                {items && items.length > 0 && items.map(item => <Video
                    key={item.id}
                    item={item}
                    active={current?.title === item.title}
                    onSelect={selectItem}
                />)}
            </List>
            <Sheet opened={!!current} onBackdropClick={unSelectItem}>
                    {!!current && <>
                    <Navbar
                        title={formatDate(current.date)}
                        right={<Link navbar onClick={unSelectItem}>Chiudi</Link>}
                    />
                    <Block>
                        <img
                            className="ios:rounded-lg material:rounded-full"
                            src={current.media.thumbnail}
                            alt={current.title}
                        />
                        <Button large onClick={() => openYouTubeVideo(current.source.youtube)}>Guarda il video</Button>
                    </Block>
                    <Block>
                        {current.title}
                    </Block>
                    <Block>
                        {current.location.address}
                    </Block>
                    <Block>
                        {current.persons.map((p, i) => {
                            const [firstName, ] = p.split(' ');
                            return <Chip
                                key={i}
                                outline
                                className="m-0.5"
                            >
                            {firstName}
                            </Chip>
                        })}
                    </Block>
                    </>}
            </Sheet>
        </Page>
    );
}

export default Library;
