import React, { useEffect } from 'react';
import {
    Routes,
    Route,
    Outlet,
    Navigate,
    useLocation,
} from "react-router-dom";
import { App as KonstaApp, KonstaProvider } from 'konsta/react';
import { useAppSelector } from './state/hooks';
import { selectToken } from './state/slices/auth';
import Wrapper from './components/Wrapper';
import Splash from './components/pages/Splash';
import Login from './components/pages/Login';
import Library from './components/pages/Library';
import Filters from './components/pages/Filters';
import './App.css';

function RequireGuest({ children }: { children: JSX.Element }) {
    const token = useAppSelector(selectToken);

    if (!!token) {
        return <Navigate to="/library" replace />;
    }

    return children;
}

function RequireAuth({ children }: { children: JSX.Element }) {
    const token = useAppSelector(selectToken);
    const location = useLocation();

    if (!token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

function App() {
    return (
        <KonstaProvider theme="ios">
          <KonstaApp theme="ios" className="k-ios">
                <div className="App">
                    <Routes>
                        <Route element={<Wrapper />}>
                            <Route path="/" element={<Splash />} />
                            <Route element={<RequireGuest><Outlet /></RequireGuest>}>
                                <Route path="/login" element={<Login />} />
                            </Route>
                            <Route element={<RequireAuth><Outlet /></RequireAuth>}>
                                <Route path="/library" element={<Library />} />
                                <Route path="/filters" element={<Filters />} />
                            </Route>
                        </Route>
                        <Route path="*" element={<Navigate to={'/'} />} />
                    </Routes>
                </div>
            </KonstaApp>
        </KonstaProvider>
    );
}

export default App;
