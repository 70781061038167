import React, { useCallback, useState } from 'react';
import { Block, BlockTitle, List, ListButton, ListInput, Navbar, Page } from 'konsta/react';
import useData from '../../hooks/useData';
import { useAppDispatch } from '../../state/hooks';
import { setToken } from '../../state/slices/auth';

function Login() {
    const [val, setVal] = useState('');
    const dispatch = useAppDispatch();
    const { login } = useData();

    const submit = useCallback(async () => {
        try {
            const token = await login({
                phone: val,
            });
            
            dispatch(setToken(token));
            // @todo add welcome
        } catch(e) {
            // @todo add errors
        }
    }, [val]);

    return (
        <Page>
            <Navbar title="Login" />

            <BlockTitle>Indica chi sei</BlockTitle>
            <List strongIos insetIos>
                <ListInput
                    type="text"
                    placeholder="Email o telefono"
                    onChange={(e) => setVal(e.target.value)}
                />
                <ListButton onClick={submit}>Entra</ListButton>
            </List>
        </Page>
    );
}

export default Login;
