import { useCallback, useContext } from "react";
import { IMessage } from "../types/app";
import { DataContext } from '../providers/Data';

export default function useMessages() {
    const { getMessagesStream } = useContext(DataContext);
 
    const addMessage = useCallback((msg: IMessage) => {
        console.log('new msg', msg)
        getMessagesStream().next(msg); 
    }, []);
    
    return {
        addMessage,
        getMessagesStream,
    }
}